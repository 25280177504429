
import {defineComponent} from "vue";
import RowCol from "@/components/base/common/RowCol.vue";
import Lookup from "@/components/base/lookup/Lookup.vue";

export default defineComponent({
  name: "Aetna",
  components: {Lookup, RowCol},
  props: {
    data: {type: Object, required: true}
  }
})
