import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!
  const _component_Lookup = _resolveComponent("Lookup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RowCol, {
      label: "Aetna Claim ID",
      value: _ctx.data.aetnaId,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Type",
      value: _ctx.data.aetnaType,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Segments",
      value: _ctx.data.segmentCount,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Suffix",
      value: _ctx.data.suffix,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Member ID",
      value: _ctx.data.memberId,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Anesthesia minutes",
      value: _ctx.data.anesthesiaMinute,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Anesthesia units",
      value: _ctx.data.anesthesiaUnit,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "COB IND",
      value: _ctx.data.cobInd,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Medicaid paid amount",
      value: _ctx.data.medicaidPaidAmt,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Federal tax",
      value: _ctx.data.federalTaxNum,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Patient account number",
      value: _ctx.data.patientAccNumber,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Principal Dx",
      value: _ctx.data.diag1,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createVNode(_component_Lookup, {
          code: _ctx.data.diag1,
          type: "ICD10CM"
        }, null, 8, ["code"])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}