
import {defineComponent} from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Lookup from "@/components/base/lookup/Lookup.vue";

export default defineComponent({
  name: "LinesAetna",
  components: {Lookup, CurrencyFormat, DateTimeFormat},
  props: {
    lines: {type: Array},
    currency: {type: String},
  }
})
